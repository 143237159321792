<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.contact"
        class="start-chat-area"
      >
        <div
          v-if="$store.state['app-chat'].loads.chatLog"
          class="d-flex justify-content-center"
        >
          <b-spinner
            variant="primary"
            class="mr-1"
            small
            type="grow"
          />
        </div>
        <div
          v-else
          class="mb-1 start-chat-icon"
        >
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />

        </div>

      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.contact.avatar"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChat.contact.status)"
                @click.native="shallShowActiveChatContactSidebar=false"
              />
              <div class="d-flex flex-column">
                <h6 class="mb-0 d-block">
                  {{ activeChat.contact.fullName }}
                </h6>
                <div class="font-size-8 text-gray">
                  {{ activeChat.contact.status }}
                </div>

              </div>

              <!-- Contact Actions -->
              <!--            <div class="d-flex align-items-center">-->
              <!--              <feather-icon-->
              <!--                icon="PhoneCallIcon"-->
              <!--                size="17"-->
              <!--                class="cursor-pointer d-sm-block d-none mr-1"-->
              <!--              />-->
              <!--              <feather-icon-->
              <!--                icon="VideoIcon"-->
              <!--                size="17"-->
              <!--                class="cursor-pointer d-sm-block d-none mr-1"-->
              <!--              />-->
              <!--              <feather-icon-->
              <!--                icon="SearchIcon"-->
              <!--                size="17"-->
              <!--                class="cursor-pointer d-sm-block d-none mr-50"-->
              <!--              />-->
              <!--              <div class="dropdown">-->
              <!--                <b-dropdown-->
              <!--                  variant="link"-->
              <!--                  no-caret-->
              <!--                  toggle-class="p-0"-->
              <!--                  right-->
              <!--                >-->
              <!--                  <template #button-content>-->
              <!--                    <feather-icon-->
              <!--                      icon="MoreVerticalIcon"-->
              <!--                      size="17"-->
              <!--                      class="align-middle text-body"-->
              <!--                    />-->
              <!--                  </template>-->
              <!--                  <b-dropdown-item>-->
              <!--                    View Contact-->
              <!--                  </b-dropdown-item>-->
              <!--                  <b-dropdown-item>-->
              <!--                    Mute Notifications-->
              <!--                  </b-dropdown-item>-->
              <!--                  <b-dropdown-item>-->
              <!--                    Block Contact-->
              <!--                  </b-dropdown-item>-->
              <!--                  <b-dropdown-item>-->
              <!--                    Clear Chat-->
              <!--                  </b-dropdown-item>-->
              <!--                  <b-dropdown-item>-->
              <!--                    Report-->
              <!--                  </b-dropdown-item>-->
              <!--                </b-dropdown>-->
              <!--              </div>-->
              <!--            </div>-->
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
          @ps-y-reach-start="buscarNovasMensagens"
        >

          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <div class="wrapper-emoji-box">

            <emoji-picker
              :search="search"

              @emoji="appendEmoji"
            >
              <button
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                class="emoji-invoker"
                type="button"
                @click.stop="clickEvent"
              >
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 fill-current text-grey"
                >
                  <path
                    d="M0 0h24v24H0z"
                    fill="none"
                  />
                  <path
                    d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                  />
                </svg>
              </button>
              <div
                slot="emoji-picker"
                slot-scope="{ emojis, insert, display }"
              >
                <div
                  class="emoji-picker"
                >
                  <div class="emoji-picker__search">
                    <input
                      v-model="search"
                      type="text"
                      placeholder="Pesquisar..."
                    >
                  </div>
                  <div>
                    <div
                      v-for="(emojiGroup, category) in emojis"
                      :key="category"
                    >
                      <h5>{{ category }}</h5>
                      <div class="emojis">
                        <span
                          v-for="(emoji, emojiName) in emojiGroup"
                          :key="emojiName"
                          :title="emojiName"
                          @click="insert(emoji)"
                        >{{ emoji }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>
          </div>
          <b-form-input
            v-model="chatInputMessage"
            placeholder="Escreva sua mensagem"
            :disabled="$store.state['app-chat'].loads.sendMessage"
            autocomplete="off"
            :autofocus="true"
            @paste="handlePaste"
          />
          <b-modal
            v-model="modalShow"
            title="Colar imagem da área de transferência"
            ok-title="Enviar imagem"
            cancel-title="Cancelar"
            ok-variant="success"
            cancel-variant="light"
            @ok="enviarImgChat"
            @cancel="hideModal"
          >
            <div v-if="imageRenderChat">
              <img
                :src="imageRenderChat"
                width="100%"
              >
            </div>

          </b-modal>

          <b-spinner
            v-if="$store.state['app-chat'].loads.sendMessage"
            variant="success"
            class="ml-1"
            small
            type="grow"
          />

          <b-button
            v-else
            class="ml-1"
            variant="success"
            type="submit"
          >
            Enviar
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <!--    <chat-active-chat-content-details-sidedbar-->
    <!--      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"-->
    <!--      :contact="activeChat.contact || {}"-->
    <!--    />-->

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">

      <chat-left-sidebar
        :chats-contacts="chatsContactsWatcher"
        :contacts="contacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.uuid : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
        @update:searchQuery="handleSearchQueryUpdate"
      />

    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  computed, nextTick, onBeforeUnmount, ref,
} from '@vue/composition-api'
import {
  BAvatar, BButton, BForm, BFormInput, BModal, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { getUserData } from '@/auth/utils'
import userDefaultAvatar from '@/assets/images/avatars/user-default-avatar.png'
import { lerMensagemChat } from '@/@core/mixins/atualizacoes'
import EmojiPicker from 'vue-emoji-picker'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

export default {
  components: {

    // BSV
    BAvatar,
    BSpinner,
    BForm,

    BFormInput,
    BButton,
    EmojiPicker,
    BModal,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatLog,
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    const profileUserDataMinimal = ref({})
    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const contacts = ref([])
    const user = getUserData()
    const fetchChatAndContacts = () => {
      store.commit('app-chat/alterarLoads', 'chats')
      store.dispatch('app-chat/fetchChatsAndContacts')
        .then(response => {
          chatsContacts.value = response.data.data

          store.commit('app-chat/alterarLoads', 'chats')

          profileUserDataMinimal.value = {
            id: user.uuid,
            fullname: user.name,
            avatar: user.imagem || userDefaultAvatar,
            status: 'online',
          }
        })
    }
    const intervalId = setInterval(fetchChatAndContacts, 5 * 60 * 1000)
    onBeforeUnmount(() => {
      clearInterval(intervalId)
    })
    const fetchContacts = searchTerm => store.dispatch('app-chat/fetchContacts', {
      q: searchTerm,
      perPage: 1000,
      page: 1,
      sortBy: 'name',
    })
      .then(response => {
        contacts.value = response.data.data
      })

    fetchChatAndContacts()
    fetchContacts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChatOfContact = userId => {
      store.commit('app-chat/alterarLoads', 'chatLog')
      store.commit('app-chat/alterarForceCloseContactsSidebar')
      const pageAtual = 1
      // Reset send message input value
      chatInputMessage.value = ''

      store.dispatch('app-chat/getChat', {
        userId,
        pageAtual,
      })
        .then(response => {
          activeChat.value = response.data
          activeChat.value.chat = activeChat.value.chat.reverse()
          store.commit('app-chat/alterarLoads', 'chatLog')
          store.commit('app-chat/setChatAberto', response.data.contact.id)

          lerMensagemChat(response.data.id)
          const foundChat = chatsContacts.value.findIndex(chat => chat.chat.id === response.data.id)

          if (foundChat !== -1) {
            chatsContacts.value[foundChat].chat.unseenMsgs = 0
          }

          // Scroll to bottom
          nextTick(() => {
            scrollToBottomInChatLog()
          })
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const sendMessage = () => {
      if (!chatInputMessage.value) return

      const payload = {
        contactId: activeChat.value.contact.id,
        chatId: activeChat.value.id,
        message: chatInputMessage.value,
      }

      chatInputMessage.value = null
      store.commit('app-chat/alterarLoads', 'sendMessage')
      store.dispatch('app-chat/sendMessage', payload)
        .then(response => {
          chatInputMessage.value = null
          store.commit('app-chat/alterarLoads', 'sendMessage')
          const newMessageData = response.data.data
          // Add message to log
          activeChat.value.chat.push(newMessageData)

          lerMensagemChat(activeChat.value.id)
          // Reset send message input value

          payload.value = {}
          // Set Last Message for active contact
          const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)

          if (contact) {
            contact.chat.last_message = newMessageData
          } else {
            fetchChatAndContacts()
          }

          // Scroll to bottom
          nextTick(() => {
            scrollToBottomInChatLog()
          })
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser')
        .then(response => {
          profileUserData.value = response.data
          shallShowUserProfileSidebar.value = true
        })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const startConversation = () => {
      mqShallShowLeftSidebar.value = !mqShallShowLeftSidebar.value
    }

    store.state.app.chatChannel.bind('message', data => {
      const foundChat = chatsContacts.value.findIndex(chat => chat.id === data.senderId)
      const isCurrentChat = data.senderId === store.state['app-chat'].chatAberto

      if (isCurrentChat) {
        lerMensagemChat(data.chatId)

        activeChat.value.chat.push(data)

        if (foundChat !== -1) {
          chatsContacts.value[foundChat].chat.unseenMsgs = 0
        }

        nextTick(scrollToBottomInChatLog)
      }

      if (foundChat !== -1) {
        chatsContacts.value[foundChat].chat.last_message = data
        chatsContacts.value[foundChat].chat.unseenMsgs = isCurrentChat ? 0 : chatsContacts.value[foundChat].chat.unseenMsgs + 1
      } else {
        fetchChatAndContacts()
      }
    })

    const chatsContactsWatcher = computed(() => chatsContacts.value || [])

    const handleSearchQueryUpdate = newSearchQuery => {
      store.dispatch('app-chat/fetchContacts', {
        q: newSearchQuery,
        perPage: 1000,
        page: 1,
        sortBy: 'name',
      })
        .then(response => {
          contacts.value = response.data.data
        })
    }

    const buscarNovasMensagens = async () => {
      const pageAtual = Number(store.state['app-chat'].paginacao.pageAtual) + 1

      const { userId } = store.state['app-chat'].paginacao
      store.commit('app-chat/alterarLoads', 'chatLog')

      store.commit('app-chat/setPage', {
        pageAtual,
        userId,
      })

      try {
        const response = await store.dispatch('app-chat/getChat', {
          userId,
          pageAtual,
        })

        const antigasMensagens = activeChat.value.chat
        const novasMensagens = response.data.chat.reverse()
        activeChat.value.chat = novasMensagens.concat(antigasMensagens)
        store.commit('app-chat/alterarLoads', 'chatLog')

        const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
        scrollEl.scrollTop += 10
      } catch (error) {
        console.error(error)
      }
    }

    const search = ref('')
    const appendEmoji = emoji => {
      chatInputMessage.value = chatInputMessage.value ? chatInputMessage.value + emoji : emoji
    }

    const pastedFile = ref(null)
    const imageData = ref(null)
    const imageRenderChat = ref(null)

    const modalShow = ref(false)

    const hideModal = () => {
      modalShow.value = false
    }

    const enviarImgChat = () => {
      chatInputMessage.value = imageData.value
      sendMessage()
    }

    const handlePaste = event => {
      const { items } = event.clipboardData || event.originalEvent.clipboardData
      for (const index in items) {
        const item = items[index]
        if (item.type && item.type.indexOf('image') !== -1) {
          const blob = item.getAsFile()
          const reader = new FileReader()
          reader.onload = event => {
            imageRenderChat.value = event.target.result
            imageData.value = blob
            modalShow.value = true
          }
          reader.readAsDataURL(blob)
          break
        }
      }
    }
    return {
      appendEmoji,
      enviarImgChat,
      fetchContacts,
      hideModal,
      handlePaste,
      imageRenderChat,
      imageData,
      modalShow,
      pastedFile,
      search,

      // useChat
      resolveAvatarBadgeVariant,
      handleSearchQueryUpdate,
      buscarNovasMensagens,

      // Chat & Contacts
      chatsContacts,
      contacts,
      chatsContactsWatcher,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
      $themeBreakpoints,

    }
  },

}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";

.wrapper-emoji-box {
  position: relative;
  display: block;
  padding: 0 10px;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, .5);
}

.emoji-invoker {
  position: relative;

  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}

.emoji-invoker:hover {
  transform: scale(1.1);
}

.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  bottom: 25px;
  right: 25px;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}

.emoji-picker__search {
  display: flex;
}

.emoji-picker__search > input {
  flex: 1;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 2px;
  outline: none;
}

.emoji-picker__search > input::placeholder {
  color: #c2c2c2;
  font-size: 12px;
}

.emoji-picker h5 {
  margin: 10px 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}

.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}

.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}

.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

</style>
